<div class="container-fluid video-container"
>
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="block.show_titles"
           [ngClass]="block.block_video || hasBlockImage() ? 'col-md-5 px-0 px-md-3' : 'col-md-12 text-center'">
        <h4 [style.color]="block.titles_color"
        [ngClass]="{'cursor-pointer': hasTitleLink()}"
        (click)="gotToLink()">
          {{ block.title }}
        </h4>
        <div
        [style.color]="block.titles_color"
        class="subtitle-block"
        [ngStyle]="{ color: block.secondary_color ? block.secondary_color : '' }"
      >
        <!-- Contenedor que controla la visibilidad -->
        <div 
          [class.collapsed]="!showFullText" 
          style="font-size: 1.3rem"
          [innerHtml]="subtitleText" 
          [ngStyle]="{ 'background-color': block.background_color ? block.background_color : '' }"
          class="content subtitle-block">

       
        </div>
        <!-- <a href="javascript:void(0)" (click)="toggleText()" class="toggle-text mb-2">
          {{ showFullText ? 'Mostrar más' : 'Mostrar menos' }}
        </a> -->
        <div
        *ngIf="block.subtitle&&removeHtmlTags(block.subtitle).length > 340" 
         [style.color]="block.link_color"
          class="font-weight-bold textFont pointer view-more"
          (click)="toggleText()"
        >
        {{ showFullText ? 'Mostrar más' : 'Mostrar menos' }}
        </div>


        <div class="row" *ngIf="block?.btn_1_active || block?.btn_2_active" [ngClass]="{
          'align-left': block?.btn_1_align === 'left' ,
          'align-center': block?.btn_1_align === 'center' ,
          'align-right': block?.btn_1_align === 'right' 
        }">
          <a *ngIf="block?.btn_1_active" [href]="block?.btn_1_link" [target]="block?.btn_1_in_new_tab ? '_blank' : '_self'"
            [state]="{ ignoreLoadingBar: true }" class="btn btn-info btn-flex m-3">
            {{ language === 'en' ? block.btn_1_en : block.btn_1_es }}
          </a>
          <a *ngIf="block?.btn_2_active" [href]="block?.btn_2_link" [target]="block?.btn_2_in_new_tab ? '_blank' : '_self'"
            [state]="{ ignoreLoadingBar: true }" class="btn btn-info btn-flex m-3">
            {{ language === 'en' ? block.btn_2_en : block.btn_2_es }}
          </a>
        </div>

        

        <!-- Botón para alternar -->
       
      </div>
      
      </div>
      <div class="col-md-7 px-0 px-md-3 col-12" *ngIf="block.block_video">
        <div
          class="thumbContainer hover-shadow"
          style="margin-bottom: 0"
          (click)="openVideoModal(block.block_video_id)"
          data-toggle="modal"
          [attr.data-target]="'#modal-video-' + block.block_video_id"
        >
          <img
            alt="Video thumbnail"
            [src]="block.block_video.thumbnail.replace('small', 'medium')"
            onerror="this.src='assets/images/video_brok.jpg'"
            loading="lazy"
            decoding="async"
          />
          <div class="playContainer">
            <img
              alt="Play button"
              class="playButton"
              src="assets/images/lesson/play.svg"
              loading="lazy"
              decoding="async"
              height="45"
              width="45"
            />
          </div>
        </div>
      </div>
      <div class="col-md-7 col-12 px-0" *ngIf="!block.block_video && hasBlockImage()">
        <img
          alt="Image"
          [src]="block.block_image.medium"
          [srcset]="block.block_image | srcset"
          loading="lazy"
          decoding="async"
          class="img-fluid"
        />
      </div>
    </div>

    
  </div>
</div>

<div
  class="modal fade"
  [id]="'modal-video-' + block.block_video_id"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Video Modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
   

        <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <img
          alt="Video image"
          *ngIf="!videoLoaded"
          class="img-fluid"
          style="margin: auto"
          src="assets/images/loading.gif"
          loading="lazy"
          decoding="async"
        />
        <div  class="playerWrap" *ngIf="block.block_video_id">
          <iframe
            *ngIf="videoLoaded"
            [src]="company.base_url + '/video-player/' + block.block_video_id | safe"
            style="width: 100%; border: none; aspect-ratio: 4/3"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
