 
<header class="cover"  *ngIf="!login">

  <div class="cover--imageBackground">
    <img
      *ngIf="isPhoneView()"
      alt="Cover image"
      [src]="phoneImageUrl"
      loading="eager"
      decoding="async"
      [srcset]="phoneImageUrl | srcset"
      sizes="100vw"
    />
    <img
      *ngIf="isDesktopView()"
      alt="Cover image"
      [src]="desktopImageUrl"
      loading="eager"
      decoding="async"
      [srcset]="desktopImageUrl | srcset"
      sizes="100vw"
    />
  </div>
   

  <div id="newaccountblock" class="container" *ngIf="!subscription_id&&!login">
    <div class="row">
      <div class="col-md-8 col-lg-6 p-4 position-relative mt-5">
        <div class="transparent-bg d-none d-md-block"></div>
        <div class="row">
          <div class="col-12">
            <h3 class="cover--title text-primary mb-0 titleTextFont">
              {{ company.home_text_title }}
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-9 col-md-6">
            <hr/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 col-lg-12">
            <p class="cover--description text-secondary mt-1">
              {{ company.home_text_description }}
            </p>
          </div>
       
        </div>
     
        <div id="newaccountbtn" class="row" *ngIf="company.allow_registration">
          <div class="col-6">
            <a
              *ngIf="view_plans_logout"
              href="home#pricing"
              [state]="{ ignoreLoadingBar: true }"
              class="btn btn-info btn-flex btn-click-effect mt-3"
            >{{'Create Account' | translate}}</a
            >
            <a
              *ngIf="!view_plans_logout"
              [routerLink]="['/register']"
              [state]="{ ignoreLoadingBar: true }"
              class="btn btn-info btn-flex btn-click-effect mt-3"
            >{{'Create Account' | translate}}</a
            >
          </div>

          <div class="col-6" *ngFor="let button of btnArray">
            <a
              [href]="[button.link]"
              [target]="button.innewtab ? '_blank' : '_self'"
              [state]="{ ignoreLoadingBar: true }"
              class="btn btn-info btn-flex btn-click-effect mt-3"
            >
              {{ button.name[company.language] }} <!-- Cambiar 'es' por 'en' para inglés -->
            </a>
          </div>
          
        </div>

         

        <div class="row d-md-none d-block">
          <div class="col-md-10 col-lg-12 mt-4">
            <p class="cover--description text-secondary mt-1">
              {{ 'If you already have an account' | translate }}
            </p>
          </div>
        </div>
        <div class="row d-md-none d-block" >
          <div class="col">
            <a
            [routerLink]="['/login']"
              [state]="{ ignoreLoadingBar: true }"
              class="btn btn-info btn-flex btn-click-effect mt-3"
            >{{'Login' | translate}}</a
            >
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div *ngIf="!subscription_id">
<!-- Blocks -->
<div class="row justify-content-center" *ngFor="let block of blocks">
  <app-block
  
    [id]="block.show_in_menu ? block?.show_in_menu_title?.toLowerCase() : null"
    [block]="block"
    [company]="company"
    [logged]="false"
  >
  </app-block>
</div>
<!-- End Blocks  -->
<!-- Home Video -->
<div
  *ngIf="company.home_video_title && company.home_video_description"
  [style.background]="company.home_background_color"
  class="container-fluid video-container"
>
  <div class="container">
    <div class="row align-items-center">
      <div
        [ngClass]="company.home_video ? 'col-md-5 px-0 px-md-3' : 'col-md-12 text-center'"
      >
        <h4 [style.color]="company.home_font_color">
          {{ company.home_video_title }}
        </h4>
        <p style="font-size: 1.3rem" [style.color]="company.home_font_color">
          {{ company.home_video_description }}
        </p>
      </div>
      <div class="col-md-7 px-0 px-md-3" *ngIf="company.home_video">
        <div
          class="thumbContainer hover-shadow"
          style="margin-bottom: 0"
          (click)="openVideoModal(videoId)"
          data-toggle="modal"
          [attr.data-target]="'#modal-video'"
        >
          <img
            alt="Video thumbnail"
            [src]="company.home_video.thumbnail.replace('small', 'medium')"
 
            onerror="this.src='assets/images/video_brok.jpg'"
            loading="lazy"
            decoding="async"
          />
          <div class="playContainer">
            <img
              alt="Play button"
              class="playButton"
              src="assets/images/lesson/play.svg"
              loading="lazy"
              decoding="async"
              height="45"
              width="45"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Home Video -->
<!-- Home Slider -->
<div
  *ngIf="slider.length > 0"
  id="home-slider"
  class="carousel slide"
  data-ride="carousel"
>
  <div class="carousel-inner">
    <div
      *ngFor="let item of slider; let i = index"
      class="carousel-item"
      [class.active]="i === 0"
    >
      <!--add active in class-->
      <img
        [src]="item.image.large"
        [srcset]="item.image | srcset"
        sizes="100vw"
        class="d-block w-100"
        alt=""
        loading="lazy"
        decoding="async"
      />
      <div
        class="carousel-caption d-md-block"
        style="padding: 2rem"
        *ngIf="item.title || item.description"
      >
        <h4
          class="text-secondary"
          style=" margin-bottom: 4rem"
          *ngIf="item.title"
        >
          {{ item?.title }}
        </h4>
        <p class="text-secondary" style=" font-size: 1.3rem" *ngIf="item.description">
          {{ item?.description }}
        </p>
        <div *ngIf="item?.title != '' && item?.description != ''" class="overlay-slider-custom"></div>
      </div>
    </div>
  </div>
  <a
    class="carousel-control-prev"
    href="#home-slider"
    role="button"
    data-slide="prev"
  >
    <!--    <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
    <div>
      <button class="btn btnRound"><img src="assets/images/mootiva/left-arrow.svg" width="40" height="40"
                                        alt="Left" class="mr-1" decoding="async" loading="lazy"></button>
    </div>

    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next"
    href="#home-slider"
    role="button"
    data-slide="next"
  >
    <!--    <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
    <div>
      <button class="btn btnRound"><img src="assets/images/mootiva/right-arrow.svg" width="40" height="40"
                                        alt="Right" class="ml-1" decoding="async" loading="lazy"></button>
    </div>
    <span class="sr-only">Next</span>
  </a>
</div>
<!-- End Home Slider -->

<!-- Courses -->
<div *ngIf="courses?.length > 0" class="container coursesList fadeIn mt-5">
  <div class="row">
    <div class="col-12 col-sm-6 offset-sm-3 pt-5 mb-3">
      <h2 class="titleTextFont pageTitle text-center">
        {{ course_text_s | translate }}
      </h2>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-4 courseItem animate__animated animate__fadeIn"
      *ngFor="let course of courses"
      [routerLink]="['/courses/profile', course.id]"
    >
      <div class="itemImage hover-shadow">
        <img
          alt="Course image"
          src="assets/images/mootiva/logo-dark.png"
          [srcset]="course.image_array | srcset"
          sizes="(max-width: 768px) 100vw, 33vw"
          loading="lazy"
          decoding="async"
        />
      </div>
      <div class="itemDetails">
        <p class="itemTitle hover-text-shadow" [innerHTML]="course.name"></p>
        <div class="row">
          <div *ngIf="show_lesson_text == 'true'">
            <div *ngIf="course?.count == 1" class="col">
              <p class="count">
                ({{ course.count }} {{ lesson_text | translate }} )
              </p>
            </div>
            <div *ngIf="course?.count > 1" class="col">
              <p class="count">
                ({{ course.count }} {{ lesson_text_s | translate }} )
              </p>
            </div>
          </div>


          <div class="col" *ngIf="course.is_just_for_suscription">
            <p class="text-right text-info font-weight-bold">
 
                {{'Accessible only with Subscription'|translate}}
            </p>
          </div>

          <div class="col" *ngIf="course.price&&!course.is_just_for_suscription">
            <p class="text-right text-info font-weight-bold">
              <span *ngIf="isDiscounted(course)"
              ><span class="badge bg-extra text-light mr-2"
              >{{ "Discount" | translate }}!</span
              >
                <span class="text-muted mr-2"
                ><del>{{
                  course.price | currency: company.currency.code
                  }}</del></span
                ></span
              >
              {{
              (isDiscounted(course)
                  ? Math.round(
                  course.price * (1 - course.discount_rate / 100) * 100
                ) / 100
                  : course.price
              ) | currency: company.currency.code
              }}
            </p>
          </div>
         
            <div class="col" *ngIf="!course.price&&!course.is_just_for_suscription">
              <p
                *ngIf="course.access_status != 'plan'"
                class="text-right text-info font-weight-bold"
              >
                {{ "Free" | translate }}
              </p>
            </div>
   
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Courses -->

<!-- Events -->
<div class="container events-list mt-5 mb-5 fadeIn" *ngIf="events?.length > 0">
  <div class="row">
    <div class="col-12 col-sm-6 offset-sm-3 mb-3">
      <h2 class="titleTextFont pageTitle text-center">
        {{ "Events" | translate }}
      </h2>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-6 col-lg-4 event-card animate__animated animate__fadeIn mb-5"
      *ngFor="let event of events"
    >
      <div class="card" [routerLink]="['/events/profile', event.id]">
        <div class="card-header">
          <div class="business">
            {{ event.bussiness }}
            <img
              class="business--image"
              [src]="event.business?.hero_image.small"
              alt="Business image"
              loading="lazy"
              decoding="async"
            />
            <div class="business--name font-weight-bold">
              {{ event.business?.name }}
            </div>
          </div>
        </div>
        <img
          alt="Event image"
          [src]="event.hero_image?.medium"
          sizes="(max-width: 768px) 100vw, (max-width:992px) 50vw, 33vw"
          class="card-img"
          decoding="async"
          loading="lazy"
          onerror="this.src='assets/images/mootiva/logo-dark.png'"
        />
        <div class="card-body">
          <div class="event-card__date">
            <span class="h5">{{ parseEventDateNumber(event) }}</span>
            <br/>
            <span class="text-danger text-capitalize font-weight-bold">{{
              parseEventMonth(event)
              }}</span>
          </div>
          <p class="event-card__schedule">
            {{ "Schedule" | translate }}: {{ parseEventStartTime(event) }} -
            {{ parseEventEndTime(event) }}
          </p>
          <p class="card-text font-weight-bold">{{ event.title }}</p>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-6 mr-auto" *ngIf="event.price">
              <p class="text-right text-info font-weight-bold h5">
                {{ event.price | currency: company.currency.code }}
              </p>
            </div>
            <!-- <div class="col-6 ml-auto text-right">
              <a href="#" class="btn btn-info mb-2">{{ "Register" | translate }}</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Events -->

<!-- Plans -->
<div
  *ngIf="view_plans_logout"
  [style.background]="
    company.home_background_color
      ? getColorRgba(company.home_background_color)
      : null
  "
>
  <div
    id="pricing"
    *ngIf="view_plans_logout"
    class="container events-list mt-5 mb-5 fadeIn"
  >

    <app-pricing-list
      id="test"
      logged="false"
      [showTrial]="hideFreePlanInHome ? 'hide' : 'show'"
    ></app-pricing-list>

  </div>

</div>
</div>

<!-- End Plans -->



<!-- Modals -->
<div
  class="modal fade"
  [id]="'modal-video'"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          #closeModal
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <img
          alt="Video image"
          *ngIf="!videoLoaded"
          class="img-fluid"
          style="margin: auto"
          src="assets/images/loading.gif"
          loading="lazy"
          decoding="async"
        />
        <div [hidden]="!videoLoaded" class="playerWrap" *ngIf="videoId">
          <iframe
            *ngIf="videoLoaded"
            [src]="company.base_url + '/video-player/' + videoId | safe"
            style="width: 100%; border: none; aspect-ratio: 4/3"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Modals -->
