<div class="container coursesList fadeIn mt-5">
  <div *ngIf="block.show_titles && courses && courses.length > 0" class="row">
    <div class="col-12">
      <h2
        class="titleTextFont font-weight-normal text-center"
        style="font-size: 2.4rem"
        [ngStyle]="{ color: block.titles_color ? block.titles_color : '' }"
        [ngClass]="{ 'cursor-pointer': hasTitleLink() }"
        (click)="gotToLink()"
      >
        {{ block.title }}
      </h2>
    </div>
    <div *ngIf="block?.subtitle" class="col-12">
      <h2
        class="font-weight-normal fadeIn text-center mb-3 subtitle-block"
        style="font-size: 1.5rem"
        [ngStyle]="{
          color: block.secondary_color ? block.secondary_color : ''
        }"
        [innerHtml]="subtitleText"
      ></h2>
    </div>
  </div>
  <div class="row">
    <div
      class="courseItem col-md-4 col-12 animate__animated animate__fadeIn"
      *ngFor="let course of courses"
      [routerLink]="['/courses/profile', course.id]"
    >
      <div class="itemImage hover-shadow">
        <picture>
          <source
            media="(max-width: 799px)"
            srcset="{{course?.image_mobile.medium}}"
          />
          <img
            sizes="(max-width: 768px) 100vw, 33vw"
            [src]="course.image.medium"
            onerror="this.src = '/assets/images/mootiva/logo-dark.png'"
          />
        </picture>
        <!-- <img
          alt="Course image"
          [src]="course.image?.small"
          [srcset]="course.image | srcset"
          sizes="(max-width: 768px) 100vw, 33vw"
          onerror="this.src = 'assets/images/mootiva/logo-dark.png'"
          loading="lazy"
          decoding="async"
        /> -->
      </div>
      <div class="itemDetails">
        <p
          class="itemTitle hover-text-shadow"
          [innerHTML]="course.name"
          [ngStyle]="{ color: block.link_color ? block.link_color : '' }"
        ></p>
        <div class="row">
          <div *ngIf="show_lesson_text == 'true'">
            <div *ngIf="course?.count == 1" class="col">
              <p class="count">
                ({{ course.count }} {{ lesson_text | translate }} )
              </p>
            </div>
            <div *ngIf="course?.count > 1" class="col">
              <p class="count">
                ({{ course.count }} {{ lesson_text_s | translate }} )
              </p>
            </div>
          </div>

          <div class="col" *ngIf="course.price; else free">
            <div *ngIf="isDiscounted(course)" class="col-12 px-0">
              <span style="font-size: 16px" class="text-muted mr-2">
                <del>{{ course.price | currency : currency.code }}</del>
              </span>
              <span
                class="vertical-separator"
                *ngIf="course?.price_global"
              ></span>
              <span
                *ngIf="course?.price_global"
                style="font-size: 16px"
                class="text-muted mr-2"
              >
                <del> {{ course.price_global | currency : "USD" : "USD" }}</del>
              </span>
            </div>
            <div
              *ngIf="
                course.access_status != 'plan' &&
                !course.is_just_for_suscription
              "
              class="d-flex align-items-center"
            >
              <div class="col-auto px-0">
                <p class="text-left text-info font-weight-bold textFont mb-0">
                  {{
                    (isDiscounted(course)
                      ? Math.round(
                          course.price * (1 - course.discount_rate / 100) * 100
                        ) / 100
                      : course.price
                    ) | currency : currency.code
                  }}
                </p>
              </div>
              <div class="col-auto align-items-center d-flex px-0">
                <span
                  class="vertical-separator"
                  *ngIf="course?.price_global"
                ></span>
              </div>
              <div class="col-auto px-0">
                <p
                  class="text-left text-info font-weight-bold textFont mb-0"
                  *ngIf="course?.price_global"
                >
                  {{
                    (isDiscounted(course)
                      ? Math.round(
                          course.price_global *
                            (1 - course.discount_rate / 100) *
                            100
                        ) / 100
                      : course.price_global
                    ) | currency : "USD" : "USD"
                  }}
                </p>
              </div>
              <div class="col-auto px-0">
                <span
                  *ngIf="isDiscounted(course)"
                  class="badge bg-extra text-light mx-2 px-3"
                  >{{ "Discount" | translate }} {{ course?.discount_rate }}%
                  OFF</span
                >
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <div
                *ngIf="
                  course.access_status != 'plan' &&
                  course.is_just_for_suscription
                "
                class="font-weight-bold textFont mb-0 accessible-sub"
              >
                {{ "Accessible only with Subscription" | translate }}
              </div>
              <div
                *ngIf="
                  course.access_status != 'plan' &&
                  course.is_just_for_suscription
                "
                class="font-weight-bold textFont pointer view-plans"
                (click)="goPlans()"
              >
                {{ "View Plans" | translate }}
              </div>
            </div>

            <p
              *ngIf="course.access_status == 'plan'"
              class="text-left text-info font-weight-bold textFont"
              title="Este curso está incluido en tu plan"
            >
              {{ "Included" | translate }}
              <del> {{ course.price | currency : currency.code }} </del>
            </p>
          </div>
          <ng-template #free>
            <div class="col">
              <div class="d-flex justify-content-between align-items-center">
                <div
                  *ngIf="
                    course.access_status != 'plan' &&
                    course.is_just_for_suscription
                  "
                  class="font-weight-bold textFont mb-0 accessible-sub"
                >
                  {{ "Accessible only with Subscription" | translate }}
                </div>
                <div
                  *ngIf="
                    course.access_status != 'plan' &&
                    course.is_just_for_suscription
                  "
                  class="font-weight-bold textFont pointer view-plans"
                  (click)="goPlans()"
                >
                  {{ "View Plans" | translate }}
                </div>
              </div>
              <p
                *ngIf="
                  course.access_status != 'plan' &&
                  !course.is_just_for_suscription
                "
                class="text-left font-weight-bold textFont text-info"
              >
                {{ "Free" | translate }}
              </p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showBtn" class="col-md-10 offset-md-1 text-center">
    <button
      class="btn btn-info mt-0 mb-5"
      tabindex="0"
      [routerLink]="['/block', block.id]"
    >
      {{ block.button_text ? block.button_text : ("View more" | translate) }}
    </button>
  </div>
  <div class="row" *ngIf="block?.btn_1_active || block?.btn_2_active" [ngClass]="{
    'align-left': block?.btn_1_align === 'left' ,
    'align-center': block?.btn_1_align === 'center' ,
    'align-right': block?.btn_1_align === 'right' 
  }">
    <a *ngIf="block?.btn_1_active" [href]="block?.btn_1_link" [target]="block?.btn_1_in_new_tab ? '_blank' : '_self'"
      [state]="{ ignoreLoadingBar: true }" class="btn btn-info btn-flex m-2">
      {{ language === 'en' ? block.btn_1_en : block.btn_1_es }}
    </a>
    <a *ngIf="block?.btn_2_active" [href]="block?.btn_2_link" [target]="block?.btn_2_in_new_tab ? '_blank' : '_self'"
      [state]="{ ignoreLoadingBar: true }" class="btn btn-info btn-flex m-2">
      {{ language === 'en' ? block.btn_2_en : block.btn_2_es }}
    </a>
  </div>
</div>
