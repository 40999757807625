<div *ngIf="true" [style]="{'color': block.titles_color ? block.titles_color:''}">
  <div *ngIf="block.show_titles" class="row">
    <div class="col-12">
      <h2 class="titleTextFont font-weight-normal text-center" style="font-size: 2.4rem;"
      [ngStyle]="{'color':block.titles_color?block.titles_color:''}"
      [ngClass]="{'cursor-pointer': hasTitleLink()}"
      (click)="gotToLink()"
      >
        {{ block.title }}
      </h2>
    </div>
    <div *ngIf="block.subtitle" class="col-12">
      <h2 class="font-weight-normal fadeIn text-center mb-3 subtitle-block" style="font-size: 1.5rem;"
          [ngStyle]="{'color':block.secondary_color?block.secondary_color:''}"
          [innerHtml]="subtitleText">
        
      </h2>
    </div>
  </div>

  <div
    id="pricing"
    class="container events-list mt-5 mb-5 fadeIn"
  >
    <app-pricing-list
      id="test"
      [logged]="logged ? 'true' : 'false'"
      [showTrial]="hideFreePlanInHome ? 'hide' : 'show'"
    ></app-pricing-list>
  </div>


 

</div>
<div class="container pl-0">  
  <div class="row mt-4" *ngIf="block?.btn_1_active || block?.btn_2_active" [ngClass]="{
    'align-left': block?.btn_1_align === 'left' ,
    'align-center': block?.btn_1_align === 'center' ,
    'align-right': block?.btn_1_align === 'right' 
  }">
    <a *ngIf="block?.btn_1_active" [href]="block?.btn_1_link" [target]="block?.btn_1_in_new_tab ? '_blank' : '_self'"
      [state]="{ ignoreLoadingBar: true }" class="btn btn-info btn-flex mx-3">
      {{ language === 'en' ? block.btn_1_en : block.btn_1_es }}
    </a>
    <a *ngIf="block?.btn_2_active" [href]="block?.btn_2_link" [target]="block?.btn_2_in_new_tab ? '_blank' : '_self'"
      [state]="{ ignoreLoadingBar: true }" class="btn btn-info btn-flex">
      {{ language === 'en' ? block.btn_2_en : block.btn_2_es }}
    </a>
  </div>
</div>