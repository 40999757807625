
<div class="row justify-content-center" *ngFor="let block of blocks">

  <app-block
  
    [id]="block.show_in_menu ? block?.show_in_menu_title?.toLowerCase() : null"
    [block]="block"
    [company]="company"
    [logged]="false"
  >
  </app-block>
</div>
