<ul
  class="navbar-nav mr-n3 mx-lg-auto px-0 {{ stickyClass }}"
  [class.white-text-navbar]="currentUrl == '/login' || currentUrl == 'register'"
>
  <li
    class="nav-item"
    [ngClass]="{ 'menu-item-has-child': menuItem?.type == 'sub' }"
    *ngFor="let menuItem of items.slice(0, 7)"
  >
    <a
      class="nav-link"
      [style.color]="menuColor"
      [class.white-text-navbar]="
        currentUrl == '/login' || currentUrl == '/register'
      "
      [routerLink]="['/', menuItem?.state]"
      routerLinkActive="active-link"
      *ngIf="menuItem?.type == 'link'"
      >{{ menuItem?.name | translate }}
    </a>
    <a
      class="nav-link"
      [style.color]="menuColor"
      [state]="{ ignoreLoadingBar: true }"
      [class.white-text-navbar]="
        currentUrl == '/login' || currentUrl == '/register'
      "
      [fragment]="menuItem?.fragment"
      [routerLink]="['/', menuItem?.state]"
      [class.active-link]="
        currentUrl == '/' + menuItem?.state + '#' + menuItem?.fragment
      "
      *ngIf="menuItem?.type == 'fragment' && menuItem?.fragment != 'null'"
      >{{ menuItem?.name | translate }}
    </a>

    <a
      class="nav-link"
      [state]="{ ignoreLoadingBar: true }"
      [style.color]="menuColor"
      [class.white-text-navbar]="
        currentUrl == '/login' || currentUrl == '/register'
      "
      [class.active-link]="currentUrl == '/home'"
      [routerLink]="['/', menuItem?.state]"
      *ngIf="menuItem?.type == 'custom'"
      >{{ menuItem?.name | translate }}
    </a>
    <a
      class="nav-link"
      [style.color]="menuColor"
      [routerLink]="['/', menuItem?.state[0], menuItem?.state[1]]"
      [queryParams]="menuItem?.query"
      routerLinkActive="active-link"
      *ngIf="menuItem?.type == 'nested'"
      [id]="menuItem?.id ? menuItem?.id : ''"
      >{{ menuItem?.name | translate }}
      <span *ngIf="menuItem?.id == 'messages'" class="countBadge"> 2 </span>
    </a>

    <a
      class="nav-link d-block d-lg-none"
      [style.color]="menuColor"
      [routerLink]="['/', menuItem?.state[0], menuItem?.state[1]]"
      [queryParams]="menuItem?.query"
      routerLinkActive="active-link"
      *ngIf="menuItem?.type == 'mobile'"
      [id]="menuItem?.id ? menuItem?.id : ''"
      >{{ menuItem?.name | translate }}
    </a>

    <a
      class="nav-link"
      [style.color]="menuColor"
      *ngIf="menuItem?.type == 'sub'"
      href="javascript:void(0);"
      >{{ menuItem?.name | translate }}</a
    >
    <a
      class="nav-link"
      [style.color]="menuColor"
      [class.white-text-navbar]="
        currentUrl == '/login' || currentUrl == '/register'
      "
      [href]="'https://' + domain + '/page/' + menuItem?.state"
      target="_blank"
      *ngIf="menuItem?.type == 'menuPages'"
      >{{ menuItem?.name | translate }}
    </a>
    <ul
      class="sub-menu list-unstyled pr-lg-0"
      *ngIf="menuItem?.type == 'sub' && menuItem?.children"
    >
      <li
        class="nav-item"
        *ngFor="let grandchildItem of menuItem?.children"
        [ngClass]="{ 'menu-item-has-child': grandchildItem.type == 'subchild' }"
      >
        <a
          class="nav-link"
          [routerLink]="['/' + menuItem?.state + '/' + grandchildItem.state]"
          routerLinkActive="active-link"
          *ngIf="grandchildItem.type == 'link'"
          >{{ grandchildItem?.name }}
        </a>

        <a
          class="nav-link"
          href="javascript:void(0);"
          *ngIf="grandchildItem.type == 'subchild'"
          >{{ grandchildItem?.name | translate }}</a
        >

        <ul class="sub-menu list-unstyled">
          <li class="nav-item" *ngFor="let subChild of grandchildItem.children">
            <a
              class="nav-link"
              *ngIf="subChild.type == 'link'"
              routerLinkActive="active-link"
              [routerLink]="['/' + grandchildItem.state + '/' + subChild.state]"
            >
              {{ subChild.name | translate }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </li>

  <!-- <li class="nav-item d-block" *ngIf="show_extra_menu">
    <a
      class="nav-link"
      [href]="url_extra_menu"
      target="_blank"
      [style.color]="menuColor"
    >
      {{ text_extra_menu }}
    </a>
  </li>

  <li class="nav-item d-block d-lg-none" *ngIf="authService.isAuthenticated()">
    <a
      (click)="logOut()"
      class="nav-link"
      href="javascript:void(0)"
      [style.color]="menuColor"
    >
      {{ "Logout" | translate }}
    </a>
  </li> -->
  <li class="nav-item" *ngIf="items.length > 7">
    <a
      class="nav-link dropdown-toggle"
      id="navbarDropdown"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Ver más
    </a>
    <div
      class="dropdown-menu"
      aria-labelledby="navbarDropdown"
      *ngIf="(items | slice : 7).length > 0"
    >
      <li
        class="nav-item"
        [ngClass]="{ 'menu-item-has-child': menuItem?.type == 'sub' }"
        *ngFor="let menuItem of items | slice : 7"
      >
        <a
          class="dropdown-item"
          [style.color]="menuColor"
          [class.white-text-navbar]="
            currentUrl == '/login' || currentUrl == '/register'
          "
          [routerLink]="['/', menuItem?.state]"
          routerLinkActive="active-link"
          *ngIf="menuItem?.type == 'link'"
          >{{ menuItem?.name | translate }}
        </a>
        <a
          class="dropdown-item"
          [style.color]="menuColor"
          [state]="{ ignoreLoadingBar: true }"
          [class.white-text-navbar]="
            currentUrl == '/login' || currentUrl == '/register'
          "
          [fragment]="menuItem?.fragment"
          [routerLink]="['/', menuItem?.state]"
          [class.active-link]="
            currentUrl == '/' + menuItem?.state + '#' + menuItem?.fragment
          "
          *ngIf="menuItem?.type == 'fragment' && menuItem?.fragment != 'null'"
          >{{ menuItem?.name | translate }}
        </a>

        <a
          class="dropdown-item"
          [state]="{ ignoreLoadingBar: true }"
          [style.color]="menuColor"
          [class.white-text-navbar]="
            currentUrl == '/login' || currentUrl == '/register'
          "
          [class.active-link]="currentUrl == '/home'"
          [routerLink]="['/', menuItem?.state]"
          *ngIf="menuItem?.type == 'custom'"
          >{{ menuItem?.name | translate }}
        </a>
        <a
          class="dropdown-item"
          [style.color]="menuColor"
          [routerLink]="['/', menuItem?.state[0], menuItem?.state[1]]"
          [queryParams]="menuItem?.query"
          routerLinkActive="active-link"
          *ngIf="menuItem?.type == 'nested'"
          [id]="menuItem?.id ? menuItem?.id : ''"
          >{{ menuItem?.name | translate }}
          <span *ngIf="menuItem?.id == 'messages'" class="countBadge"> 2 </span>
        </a>

        <a
          class="dropdown-item d-block d-lg-none"
          [style.color]="menuColor"
          [routerLink]="['/', menuItem?.state[0], menuItem?.state[1]]"
          [queryParams]="menuItem?.query"
          routerLinkActive="active-link"
          *ngIf="menuItem?.type == 'mobile'"
          [id]="menuItem?.id ? menuItem?.id : ''"
          >{{ menuItem?.name | translate }}
        </a>

        <a
          class="dropdown-item"
          [style.color]="menuColor"
          *ngIf="menuItem?.type == 'sub'"
          href="javascript:void(0);"
          >{{ menuItem?.name | translate }}</a
        >

        <a
          class="dropdown-item"
          [style.color]="menuColor"
          [class.white-text-navbar]="
            currentUrl == '/login' || currentUrl == '/register'
          "
          [href]="'https://' + domain + '/page/' + menuItem?.state"
          target="_blank"
          *ngIf="menuItem?.type == 'menuPages'"
          >{{ menuItem?.name | translate }}
        </a>
      </li>
    </div>
  </li>
</ul>
