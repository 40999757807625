import { Component, Input, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { InitProvider } from "src/app/init-provider";
import { MenuItems } from "../../../../Core/Menu/MenuItems/MenuItems";
import { AuthService } from "src/app/Services/Auth/auth.service";
import { CompanyService } from "../../../../Services/company.service";
import { BlocksService } from "../../../../Services/blocks.service";
import { BlockModel } from "../../../../Interfaces/BlockModel";

@Component({
  selector: "app-header-menu",
  templateUrl: "./HeaderMenu.component.html",
  styleUrls: ["./HeaderMenu.component.scss"],
})
export class MenuComponent implements OnInit {
  @Input() stickyClass: any;
  @Input() menuColor: string;
  currentUrl: any;
  items: any;
  show_extra_menu = false;
  text_extra_menu: string;
  course_name_menu: string;
  program_name_menu: string;
  url_extra_menu: string;
  domain: string = "";
  blocks: Array<BlockModel>;
  finishedLoadingMenu: boolean;

  constructor(
    public menuItems: MenuItems,
    private router: Router,
    public authService: AuthService,
    private initProvider: InitProvider,
    private companyService: CompanyService
  ) {}

  async ngOnInit() {
    this.currentUrl = this.router.url;
    const company = this.initProvider.getSettings();
    this.processBlocks(company.blocks);
    this.finishedLoadingMenu = true;

    const json = JSON.parse(company.faq_json);
    const user = this.authService.user;
    let user_language;
    if (user) {
      user_language = user.language;
    } else {
      user_language = company.language;
    }

    let course_text_s;

    if (
      company.menu_option_en &&
      company.menu_option_es &&
      company.menu_option_url
    ) {
      this.show_extra_menu = true;
      if (user_language === "en") {
        this.text_extra_menu = company.menu_option_en;
      } else {
        this.text_extra_menu = company.menu_option_es;
      }
      this.url_extra_menu = company.menu_option_url;
    }

    if (user_language === "en") {
      this.course_name_menu =
        json && json.texts?.menu_courses_en ? json.texts.menu_courses_en : null;
    } else {
      this.course_name_menu =
        json && json.texts?.menu_courses_es ? json.texts.menu_courses_es : null;
    }

    if (user_language === "en") {
      this.program_name_menu =
        json && json.texts?.programs_en ? json.texts.programs_en : null;
    } else {
      this.program_name_menu =
        json && json.texts?.programs_es ? json.texts.programs_es : null;
    }

    course_text_s = this.companyService.course_text_s;
    console.log(" course_text_s ", course_text_s);
    if (this.companyService.course_name_menu) {
      this.menuItems.setMenuCourseName(this.course_name_menu);
    } else {
      this.menuItems.setMenuCourseName(
        course_text_s ? course_text_s : "Courses"
      );
    }
    if (this.program_name_menu) {
      this.menuItems.setMenuProgramName(this.program_name_menu);
    }

    if (this.authService.isAuthenticated()) {
      this.items = this.menuItems.getAuthMenu();
    } else {
      this.items = this.menuItems.getMainMenu();
    }

    this.domain = company.domain;
    if (company.activePages) {
      let activePages = company.activePages.map((item) => {
        if (item.menu_title) {
          if (
            item.display_menu == "public" &&
            !this.authService.isAuthenticated()
          ) {
            return {
              state: item.slug,
              type: "menuPages",
              name: item.menu_title,
              showIn: item.display_menu,
            };
          } else {
            if (
              item.display_menu == "register" &&
              this.authService.isAuthenticated()
            ) {
              return {
                state: item.slug,
                type: "menuPages",
                name: item.menu_title ? item.menu_title : "",
                showIn: item.display_menu,
              };
            } else {
              if (item.display_menu == "both") {
                return {
                  state: item.slug,
                  type: "menuPages",
                  name: item.menu_title ? item.menu_title : "",
                  showIn: item.display_menu,
                };
              }
            }
          }
        }
      });
      this.items = [...this.items, ...activePages];
    }

    if (
      this.currentUrl !== "/session/comingsoon" &&
      this.currentUrl !== "/session/maintenance"
    ) {
      const menuItems = document.getElementsByClassName("menu-item-has-child");
      for (let i = 0; i < menuItems.length; i++) {
        (function (index) {
          menuItems[index].addEventListener("click", function (event) {
            event.stopPropagation();
            if (menuItems[index] !== undefined) {
              menuItems[index].classList.toggle("opened-submenu");
            }
          });
        })(i);
      }
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.currentUrl = this.router.url;
          if (this.currentUrl === "/home") {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }
      });
    }
  }

  showIn(permission: string) {}
  processBlocks(blocks) {
    for (const block of blocks.filter((x) => x.active)) {
      const menu = {
        name: block.show_in_menu_title,
        type: "fragment",
        fragment: `${block?.id}`,
        key: "",
        state: "/",
      };

      if (
        block.show_in_menu &&
        !!block.show_in_menu_title &&
        block.show_in_home_without_login &&
        !this.menuItems.HEADERMENUITEMS.find(
          (x) => x.name === block.show_in_menu_title
        )
      ) {
        this.menuItems.pushHeaderMenu(menu);
      }
    }
  }

  logOut() {
    this.authService.logOut();
    window.location.href = "/home";
  }

  sluggify(string) {
    if (!string || string === "") {
      return "";
    }
    return string
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }
}
