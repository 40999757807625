import {Component, Input, OnInit} from '@angular/core';
import {BlockModel} from '../../Interfaces/BlockModel';
import {CompanyModel} from '../../Models/CompanyModel';
import {CompanyService} from '../../Services/company.service';
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import {AuthService} from '../../Services/Auth/auth.service';

@Component({
  selector: 'app-block-programs',
  templateUrl: './block-programs.component.html',
  styleUrls: ['./block-programs.component.scss']
})
export class BlockProgramsComponent implements OnInit {
  @Input() block: BlockModel;
  @Input() company: CompanyModel;
  @Input() slice: number;
  @Input() logged: boolean = false;
  subtitleText:any;
  programList: Array<any>;
  currency: any;
  language='es'

  constructor(
    protected sanitizer: DomSanitizer, public authService: AuthService,
    public companyService: CompanyService
  ) {
    
    this.currency = companyService.company.currency;
  }

  ngOnInit(): void {
    //console.log('program block',this.block);
    
    this.language=this.authService.user?this.authService.user.language:this.companyService.company.language

    if (this.block.programs) {
      this.programList = this.block.programs.slice(0, this.slice);
    //  console.log(' this.programList', this.programList)
    }
    if (this.block.subtitle?.substring(0, 3)=='<p>'){ 
      const restoDelTexto = this.block.subtitle?.substring(3);
      this.subtitleText=this.sanitizer.bypassSecurityTrustHtml('<p style="color:'+this.block.secondary_color+';">'+ restoDelTexto);
    }else{
      this.subtitleText=this.block.subtitle;
    }
  }
  hasTitleLink(){
    return this.block.title_url?true:false;
  }
  gotToLink(){
    if (this.block.title_url){
      window.location.href = this.block.title_url;
    }
  }
  programLink(program: any) {
    return ['/programs/profile', program.id];
    if (this.logged) {
    }
    return ['/block', this.block.id];
  }
  isDiscounted(course) {
    return (
      course.discount_has &&
      moment(course.discount_until + " 23:59:59") > moment()
    );
  }
}
