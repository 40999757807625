<div class="container fadeIn" *ngIf="eventList && eventList.length > 0">
  <div *ngIf="block.show_titles" class="row">
    <div class="col-12">
      <h2 class="titleTextFont font-weight-normal text-center" style="font-size: 2.4rem;"
        [ngStyle]="{'color':block.titles_color?block.titles_color:''}" [ngClass]="{'cursor-pointer': hasTitleLink()}"
        (click)="gotToLink()">
        {{ block.title }}
      </h2>
    </div>
    <div *ngIf="block.subtitle" class="col-12">
      <h2 class="font-weight-normal fadeIn text-center mb-3 subtitle-block" style="font-size: 1.5rem;"
        [ngStyle]="{'color':block.secondary_color?block.secondary_color:''}" [innerHtml]="subtitleText">

      </h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-lg-4 event-card animate__animated animate__fadeIn mb-5" *ngFor="let event of eventList">
      <div class="card" [routerLink]="['/events/profile', event.id]">
        <div class="card-header">
          <div class="business">
            {{ event.bussiness }}
            <img class="business--image" [src]="event.business?.hero_image.small" alt="Business image" loading="lazy"
              decoding="async" />
            <div class="business--name font-weight-bold">
              {{ event.business?.name }}
            </div>
          </div>
        </div>
        <img alt="Event image" [src]="event.hero_image?.medium"
          sizes="(max-width: 768px) 100vw, (max-width:992px) 50vw, 33vw" class="card-img" decoding="async"
          loading="lazy" onerror="this.src='assets/images/mootiva/logo-dark.png'" />
        <div class="card-body">
          <div class="event-card__date">
            <span class="h5">{{ parseEventDateNumber(event) }}</span>
            <br />
            <span class="text-danger text-capitalize font-weight-bold">{{
              parseEventMonth(event)
              }}</span>
          </div>
          <p class="event-card__schedule">
            {{ "Schedule" | translate }}: {{ parseEventStartTime(event) }} -
            {{ parseEventEndTime(event) }}
          </p>
          <p class="card-text font-weight-bold">{{ event.title }}</p>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-6 mr-auto" *ngIf="event.price">
              <p class="text-right text-info font-weight-bold h5">
                {{ event.price | currency: company.currency.code }}
              </p>
            </div>
            <!-- <div class="col-6 ml-auto text-right">
              <a href="#" class="btn btn-info mb-2">{{ "Register" | translate }}</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="block.button_text" class="col-md-10 offset-md-1 text-center">
    <button class="btn btn-info mt-0 mb-5" tabindex="0"
      [routerLink]="['/block', block.id]">{{block.button_text}}</button>
  </div>
  <div class="row" *ngIf="block?.btn_1_active || block?.btn_2_active" [ngClass]="{
    'align-left': block?.btn_1_align === 'left' ,
    'align-center': block?.btn_1_align === 'center' ,
    'align-right': block?.btn_1_align === 'right' 
  }">
    <a *ngIf="block?.btn_1_active" [href]="block?.btn_1_link" [target]="block?.btn_1_in_new_tab ? '_blank' : '_self'"
      [state]="{ ignoreLoadingBar: true }" class="btn btn-info btn-flex mx-3">
      {{ language === 'en' ? block.btn_1_en : block.btn_1_es }}
    </a>
    <a *ngIf="block?.btn_2_active" [href]="block?.btn_2_link" [target]="block?.btn_2_in_new_tab ? '_blank' : '_self'"
      [state]="{ ignoreLoadingBar: true }" class="btn btn-info btn-flex">
      {{ language === 'en' ? block.btn_2_en : block.btn_2_es }}
    </a>
  </div>
</div>