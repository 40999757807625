import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit,Renderer2} from '@angular/core';
import {DropletService} from '../../Services/Droplet.service';
import {InitProvider} from '../../init-provider';
import {CoursesService} from 'src/app/Services/Courses.service';
import {SuscriptionService} from 'src/app/Services/Suscription.service';

import {BlocksService} from 'src/app/Services/blocks.service';
import {EventsService} from 'src/app/Services/events.service';
import * as moment from 'moment-timezone';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import * as Player from '@vimeo/player/dist/player.js';
import {BlockModel} from '../../Interfaces/BlockModel';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-preview-home',
  templateUrl: './Preview.component.html',
  styleUrls: ['./Preview.component.scss']
})
export class PreviewHomeComponent implements OnInit , AfterViewInit{
  gridOverlay: any;
  serviceItems: any;
  testimonialData: any;
  counterContent: any;
  pricingContent: any;
  company: any;
  courses = [];
  events = [];
  blocks: Array<BlockModel> = [];
  Math = Math;
  whatsapp_number = '';
  course_text = 'Course';
  lesson_text = 'Unit';
  course_text_s;
  lesson_text_s;
  show_lesson_text;
  view_plans_logout = false;
  hideFreePlanInHome = false;
  subscription_status = '';
  subscription_id = null;
  loadingSucription=false;
  suscription_email='';
  company_email='';
  company_wp=null;
  userNewHash='';
  slider = [];
  video = null;
  videoLoaded = false;
  videoId = null;
  login=false;
  phoneImageUrl = '';
  desktopImageUrl = '';
  userNewEmail='';
  btnArray=null;
  constructor(
    public service: DropletService,
    public initProvider: InitProvider,    
    public suscriptionService: SuscriptionService,
    public coursesService: CoursesService,
    public blocksService: BlocksService,
    public eventsService: EventsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private meta: Meta,
    private renderer: Renderer2
  ) {
    this.company = initProvider.getSettings();

   
  }
  ngAfterViewInit(): void {
    const rootElement = document.querySelector('body');
    rootElement?.classList.add('disable-clicks');
    

  }

  ngOnInit() {
 
    this.meta.updateTag({ name: 'viewport', content: 'width=1240' });
    this.desktopImageUrl = this.company?.home_image;
    this.phoneImageUrl = this.company?.home_image_mobile;
   
    this.company_email= this.company?.email;
    this.whatsapp_number = this.company?.whatsapp_number;
    


    if (this.company.registration_type == 2) {
      // console.log('reistration---------------2')
      this.view_plans_logout = true;

    }

 
    if (this.company.home_video) {
      this.videoId = this.company.home_video.id;
    }


    const json = JSON.parse(this.company.faq_json);
    if (json.buttons){
      this.btnArray=json.buttons;
    }
    //console.log('json.buttons',json.buttons)
    if (json && json.hide_free_plan_in_home) {
      this.hideFreePlanInHome = true;
    }
    const company_language = this.company.language;
    this.show_lesson_text = json.configuration ? json.configuration.show_lessons_in_courses : 'true';
    // console.log(json.texts.course);


    if (json.texts) {
      if (company_language == 'en') {
        this.course_text = json.texts.course_en ? json.texts.course_en : 'course';
        this.lesson_text = json.texts.lesson_en ? json.texts.lesson_en : 'unit';
        this.course_text_s = json.texts.courses_en ? json.texts.courses_en : 'courses';
        this.lesson_text_s = json.texts.lessons_en ? json.texts.lessons_en : 'unit';
      }
      if (company_language == 'es') {
        this.course_text = json.texts.course_es ? json.texts.course_es : 'Curso';
        this.lesson_text = json.texts.lesson_es ? json.texts.lesson_es : 'Unidad';
        this.course_text_s = json.texts.courses_es ? json.texts.courses_es : 'Cursos';
        this.lesson_text_s = json.texts.lessons_es ? json.texts.lessons_es : 'Unidades';

      }
      if (company_language == 'it') {
        this.course_text = json.texts.course_it ? json.texts.course_it : 'Course';
        this.lesson_text = json.texts.lesson_it ? json.texts.lesson_it : 'Unit';
        this.course_text_s = json.texts.courses_it ? json.texts.courses_it : 'Courses';
        this.lesson_text_s = json.texts.lessons_it ? json.texts.lessons_it : 'Unit';

      }
    }


  
    this.route.queryParams.subscribe(params => {
      this.login=params.login?params.login:false;
      console.log('this.login222',this.login)
      
      if (this.login){
        const header = document.getElementById('header-sec');
        console.log('sacando menu',header)

        this.renderer.setStyle(header, 'display', 'none'); 
   
      }
      this.getPublicEvents();
      this.getPublicBlocks();
      if (params.subscription_id){
        this.loadingSucription=true;
        this.subscription_id=params.subscription_id
        this.subscription_status=params.status
        this.suscriptionService.getPublicSuscriptionId(this.subscription_id).then((suscription) => {
          if (suscription){
            console.log('suscription',suscription)
            this.loadingSucription=false;
            this.suscription_email=suscription.email;
            this.userNewHash=suscription.hash;
            this.userNewEmail=suscription.user_email;
          }else{
            window.location.href='/home'
          }

        })
      }
      if (params.expired) {
        this.toastr.error('Su sesión ha expirado. Por favor inicie sesión nuevamente.');
      }
    });


    for (let i = 1; i < 6; i++) {
      if (
        this.company['home_slider_title_' + i]
        || this.company['home_slider_description_' + i]
        || (this.company['home_slider_image_' + i] && !this.imageIsPlaceholder(this.company['home_slider_image_' + i]))
      ) {
        this.slider.push({
          title: this.company['home_slider_title_' + i],
          description: this.company['home_slider_description_' + i],
          image: this.company['home_slider_image_' + i]
        });
      }
    }
    if (this.company.home_video?.url) {
      this.video = 'https://player.vimeo.com/video/' + this.company.home_video.url.substr(8) + '?embedparameter=value';
      console.log(this.video, 'video');
    }
    

  }
  getDataSuscription(id){

  }

  isPhoneView(): boolean {
    return window.matchMedia('(max-width: 767px)').matches;
  }
  
  isDesktopView(): boolean {
    return window.matchMedia('(min-width: 768px)').matches;
  }
  @HostListener('window:resize')
  onResize() {
    let isMobile = matchMedia("(max-width: 767px)").matches;
    if ( isMobile ) {
      this.phoneImageUrl = this.company?.home_image_mobile;
    } else {
      this.desktopImageUrl = this.company?.home_image;
    }
  }

  getPublicCourses() {
    this.coursesService.getPublicCourses().then((courses) => {
      this.courses = courses.data;
    }).catch((error) => {
      console.log(error);
    });
  }

  getPublicBlocks() {
    this.blocksService.getAllBlocks(null,this.login).then((blocks) => {
      //elimino los bloques vacios
      this.blocks = blocks.data.filter(block => {
        if ((block.type=='product'&&block.products&&block.products.length==0)||block.type=='product'&&!block.products){
          return false
        }
        if ((block.type=='event'&&block.events&&block.events.length==0)||block.type=='event'&&!block.events){
          return false
        }
        if ((block.type=='program'&&block.programs&&block.programs.length==0)||block.type=='program'&&!block.programs){
          return false
        }
        if ((block.type=='course'&&block.courses&&block.courses.length==0)||block.type=='course'&&!block.courses){
          return false
        }
        return true
          
        
        
      });

      console.log('this.login',this.login==true)

      this.blocks = this.blocks 
  
      .filter(block => block.page_id === null);
    


      console.log('this.blocks con filtro Ultimo',this.blocks)
      if (this.blocks.length == 0) {
        this.getPublicCourses();        
      }
      this.route.fragment.subscribe(fragment => {
        if (fragment) {
          setTimeout(() => {
            const element = document.getElementById(fragment);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
          }, 1000);
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  getPublicEvents() {
    this.eventsService.getPublicListWithFilters('from_end_date=2021-02-11%2016:53&order_name=start_date&order_type=ASC').then((events) => {
      this.events = events.data;
    }).catch((error) => {
      console.log(error);
    });

  }

  parseEventDateNumber(event) {
    const dateUTC = moment.utc(event.start_date.date);
    return dateUTC.local().date();
  }

  parseEventMonth(event) {
    const dateUTC = moment.utc(event.start_date.date);
    return dateUTC.local().format('MMM')
      .replace('.', '');
  }

  parseEventStartTime(event) {
    const dateUTC = moment.utc(event.start_date.date);
    return dateUTC.local().format('HH:mm');
  }

  parseEventEndTime(event) {
    const dateUTC = moment.utc(event.end_date.date);
    return dateUTC.local().format('HH:mm');
  }

  isDiscounted(course) {
    return course.discount_has && moment(course.discount_until) > moment();
  }

  initializeVideoPlayer(videoID) {
    this.videoLoaded = true;
    $('#modal-video').on('hide.bs.modal', () => {
      this.videoLoaded = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  openVideoModal(videoID) {
    setTimeout(() => {
      this.initializeVideoPlayer(videoID);
    }, 200);
  }

  getColorRgba(hexadecimal) {
    const hexToRgb = hexadecimal.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16));
    return `rgba(${hexToRgb[0]},${hexToRgb[1]},${hexToRgb[2]},0.7)`;
  }

  imageIsPlaceholder(image) {
    return image.small.includes('generic');
  }
}
