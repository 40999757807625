import {Injectable} from '@angular/core';
import {ApiService} from './Api.service';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  coursesUrl = 'home/courses';
  coursesForumsUrl = 'home/forums';
  coursesListUrl = 'home/list/courses';
  publicUrl = 'public/courses';
  baseDataUrl = 'data';
  lessonStatusUrl = 'lesson_status';
  contactUrl = 'contact';
  subscribeUrl = 'subscription';
  reviewUrl = 'review';
  currentCourse: any;
  courses: any;

  constructor(private apiService: ApiService) {
  }

  async getCourses() {
    try {
      const courses = await this.apiService.get(this.coursesUrl);
      this.courses = courses;
      return courses;
    } catch (error) {
      return error;
    }
  }

  async getCourseById(courseId) {
    try {
      return await this.apiService.get(this.coursesUrl.concat('/').concat(courseId));
    } catch (error) {
      return error;
    }
  }
  async getCourseForumsById(courseId) {
    try {
      return await this.apiService.get(this.coursesForumsUrl.concat('/').concat(courseId));
    } catch (error) {
      return error;
    }
  }

  async getVideoData(videoUri) {
    try {
      const videoData = await this.apiService.get(this.baseDataUrl.concat(videoUri));
      return videoData;
    } catch (error) {
      return error;
    }
  }

  async changeLessonStatus(status, lessonId) {
    try {
      const data = {
        lesson: lessonId,
        status
      };
      const videoData = await this.apiService.post(this.lessonStatusUrl, data);
      return videoData;
    } catch (error) {
      return error;
    }
  }

  async getPublicList(page = 1) {
    try {
      const courses = await this.apiService.get(this.coursesListUrl.concat('?page=' + page));
      return courses;
    } catch (error) {
      return error;
    }
  }

  async getPublicById(courseId) {
    try {
      const course = await this.apiService.get(this.publicUrl.concat('/').concat(courseId));
      return course;
    } catch (error) {
      return error;
    }
  }

  async sendContactForm(values) {
    try {
      await this.apiService.post(this.contactUrl, values);
      return true;
    } catch (error) {
      return error;
    }
  }

  async subscribeToActiveCampaign(email) {
    try {
      const response = await this.apiService.post(this.subscribeUrl, email);
      return response;
    } catch (error) {
      return error;
    }
  }

  public getSubscriptionHeaders(): {} {
    const headers = {};
    headers['Api-Token'] = 'dac882db85b93567fed982e67d4a64c9a527fc516fda33776a3ab05dca63f8abdebd8405';
    return headers;
  }

  async getListWithFilters(filters = '', page = 1) {
    try {
      return await this.apiService.get(this.coursesListUrl.concat('?page=' + page).concat(filters));
    } catch (error) {
      return error;
    }
  }

  async sendCourseReview(review) {
    try {
      return await this.apiService.post(this.reviewUrl, review);
    } catch (error) {
      return error;
    }
  }

  async purchase(courseId) {
    try {
      return await this.apiService.post('courses/' + courseId + '/purchases', []);
    } catch (error) {
      return error;
    }
  }

  async getPublicCourses(page = 1) {
    try {
      return await this.apiService.get(this.publicUrl.concat('?page=' + page));
    } catch (error) {
      return error;
    }
  }

  async getQuestions(courseId) {
    try {
      return await this.apiService.get('courses/' + courseId + '/exams');
    } catch (error) {
      return error;
    }
  }

  async sendExam(courseId, data) {
    try {
      const course = await this.apiService.post('exams/' + courseId + '/responses', {'responses': data});
      return course;
    } catch (error) {
      return error;
    }
  }

  setCurrentCourse(course) {
    this.currentCourse = course;
  }

  getCurrentCourse() {
    return this.currentCourse;
  }
  
  public sharedCourse: any = null;
  setSharedCourse(obj: any) {
    this.sharedCourse = obj;
  }

  getSharedCourse(): any {
    return this.sharedCourse;
  }
}
